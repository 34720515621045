.wrapper img {
  @apply object-cover w-full h-full;
}

@media (min-width: 769px) {
  .imageHover img {
    transition: all 0.5s ease;
  }

  .imageHover img:hover {
    transform: scale(1.05);
  }
}
