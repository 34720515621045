/* Arrow */

.arrow svg {
  transform: rotate(var(--carousel-arrow-rotation, 0));
}

.arrow[data-direction='left'] {
  --carousel-arrow-direction: -1;
  --carousel-arrow-rotation: 180deg;
  @apply left-0;
}

.arrow[data-direction='left'] svg {
  @apply transform rotate-180;
}

.arrow[data-direction='right'] {
  --carousel-arrow-direction: 1;
  @apply right-0;
}

/* State */

/* Hidden */

.arrow[data-direction='right'][data-state='hidden'] {
  transform: translate(4rem, -50%) scale(.9);
}

.arrow[data-direction='left'][data-state='hidden'] {
  transform: translate(-4rem, -50%) scale(.9);
}

.arrow[data-state='hidden'] {
  @apply opacity-0  pointer-events-none;
}

/* Shadow */

.arrow[data-shadow='true'] {
  @apply flex h-full items-center;

  background: linear-gradient(
    calc(var(--carousel-arrow-direction, 1) * -90deg),
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

/* Variant: Inside */
.arrow[data-variant='inside'] {
  @apply px-3 md:px-8 lg:px-10;
}

/* Variant: Outside */
.arrow[data-variant='outside'] {
  @apply px-3 lg:px-6 bg-none;
}

.arrow[data-variant='outside'][data-direction='left'] {
  @apply -translate-x-full;
}

.arrow[data-variant='outside'][data-direction='right'] {
  @apply translate-x-full;
}

/* Variant: Half */

.arrow[data-variant='half'] {
  background: linear-gradient(
    calc(var(--carousel-arrow-direction, 1) * -90deg),
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.arrow[data-variant='half'][data-direction='left'] {
  @apply -translate-x-1/2;
}

.arrow[data-variant='half'][data-direction='right'] {
  @apply translate-x-1/2;
}


/* Variant: Simple */
.arrow[data-variant='simple'] {
  @apply p-1.5;
}

.arrow[data-variant='simple'][data-direction='right'] {
  @apply pl-5;
}

.arrow[data-variant='simple'][data-direction='left'] {
  @apply pr-5;
}
