.lightBoxImage,
.iframeHolder {
  @apply w-full mx-auto rounded-none;
  @apply md:rounded;

  max-height: 85vh;
}

.lightBoxImage ~ :global(.loader) {
  @apply absolute top-2/4 left-0 -translate-y-2/4 pointer-events-none backdrop-blur-xl;
  @apply md:translate-y-0 md:top-0 md:left-2/4 md:-translate-x-2/4;
}

@screen lg {
  .lightBoxImage,
  .iframeHolder {
    @apply w-full object-contain;

    max-height: 80vh;
  }
}
