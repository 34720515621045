/* Spacing */

/* Normal */

.slider[data-spacing='normal'] {
  @apply -mx-2;
}

.slider[data-spacing='normal'] .arrow {
  @apply mx-2.5;
}

.slider[data-spacing='normal'] [data-index] {
  @apply px-2.5;
}

/* small */

.slider[data-spacing='small'] {
  @apply -mx-2;
}

.slider[data-spacing='small'] .arrow {
  @apply mx-2;
}

.slider[data-spacing='small'] [data-index] {
  @apply px-2;
}

/* large */

.slider[data-spacing='large'] {
  @apply -mx-3;
}

.slider[data-spacing='large'] .arrow {
  @apply mx-3;
}

.slider[data-spacing='large'] [data-index] {
  @apply px-3;
}

.slider :global(.slick-track) {
  @apply flex;
}

.slider :global(.slick-slide) {
  @apply h-auto;
}

.slider :global(.slick-slide) > div,
.slider :global(.slick-slide) > div > div {
  @apply h-full;
}

.headerPagination {
  @apply fixed top-7 w-full text-center text-white body-sm font-light z-10;
  @apply lg:top-10;
}
