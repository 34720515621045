.wrapper {
  @apply grid grid-cols-2 gap-2;
}

.col {
  @apply grid grid-flow-row auto-rows-min gap-2;
}

.image {
  @apply rounded-sm cursor-pointer w-full object-cover;
}

.image ~ :global(.loader) {
  @apply absolute min-w-full min-h-full top-0 left-0 pointer-events-none backdrop-blur-xl;
}

.imageWrapper {
  @apply overflow-hidden relative;
}

.columnSpan {
  column-span: all;
  @apply mt-2;
}

.lightBox {
  @apply h-screen;
}

.lightBox :global(.slick-list) {
  @apply flex items-center relative;
  @apply md:static;
}

.lightBox :global(.slick-track) {
  @apply flex items-center h-full;
}

.lightBox :global(.slick-slide) {
  @apply py-12 flex items-center justify-center relative;
  @apply md:static;
}

.lightBox :global(.slick-slide) > div {
  @apply basis-full flex justify-center;
}

.lightBox :global(.slick-slide) > div > div {
  @apply overflow-hidden;
  @apply md:relative md:overflow-visible md:relative;
}

.description {
  @apply font-light text-white body-sm absolute bottom-0 w-screen p-4 bg-black;
  @apply md:top-full md:w-full md:px-0 md:text-center md:bottom-auto md:bg-transparent md:pt-6;
}

.lightBoxModal > div:nth-child(3) > div:nth-child(2) {
  @apply w-full;
  @apply md:w-4/5;
}

.lightBoxModal > div:first-child {
  background-color: black !important;
}

@screen md {
  .lightBoxModal > div:first-child {
    background-color: hsl(0 0% 0% / 90%) !important;
  }
}
