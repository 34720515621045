.slideContainer[data-size='normal'] {
  @apply w-11/12;
}
@screen md {
  .slideContainer[data-size='normal'] {
    @apply w-4/5;
  }
}
.slideContainer[data-size='full'] {
  @apply w-full;
}

.dots {
  display: flex !important;
  @apply justify-center mt-4;
}

.dots > * {
  @apply inline;
}

.dots > *:not(:global(.slick-active)) {
  @apply opacity-50;
}
